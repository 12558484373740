import {Drawer, Theme} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {createStyles, makeStyles} from '@material-ui/styles';
import classnames from 'classnames';
import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {mdirInfo, naturvardsInfo, stripPhoneNumber} from '../../constants/contactInfo';
import {useLanguage} from '../../i18n';
import {ApplicationState, useActionCreator} from '../../store';
import {closeMenu} from '../../store/menu/action';
import {Site} from '../../store/settings/types';
import {LanguageSelector} from '../languageSelector/languageSelector';
import {Logo} from '../logo/logo';
import './menu.scss';
import {IconButton} from '../themeButtton/themeButton';
import {ExternalLink} from '../externalLInk/externalLink';
import {useDodeRovdyrRapport} from '../../hooks/featureToggles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuContent: {
            '& a': {
                color: theme.palette.primary.main,
            },
            '& button': {
                color: theme.palette.primary.main,
            },
        },
        menuColors: {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.dark,
        }
    })
);

export function Menu(): JSX.Element {
    const l = useLanguage();
    const [openRovdyr, setOpenRovdyr] = useState<boolean>(false);
    const open = useSelector((state: ApplicationState) => state.menu.open);
    const language = useSelector((state: ApplicationState) => state.settings.language);
    const site = useSelector((state: ApplicationState) => state.settings.site);
    const close = useActionCreator(closeMenu);
    const showReportDodeRovdyr = useDodeRovdyrRapport();
    const classes = useStyles({});
    const menuFooterClass = classnames('menu-footer', classes.menuColors);
    const expandClass = classnames('expand-more', { openRovdyr: openRovdyr });
    const menuClass = classnames(classes.menuContent, 'menu-container');
    const menuTitleContainerClass = classnames(classes.menuColors, 'menu-title-container');
    const languageContainerClass = classnames(classes.menuColors, 'language-container');

    
    return (
        <Drawer anchor="right" open={open} onClose={close}>
            <div className={menuClass}>
                <div className="menu-content">
                    <div className={menuTitleContainerClass}>
                        <h2 className="menu-title">{l('menu')}</h2>
                        <IconButton onClick={close} aria-label={l('uu.close')} className="close-menu">
                            <CloseIcon color="primary" titleAccess={`${l('uu.close')} ${l('menu').toLowerCase()}`} />
                        </IconButton>
                    </div>
                    <div className={languageContainerClass}>
                        <LanguageSelector />
                    </div>
                    
                    {/* Main site navigation */}
                    <nav>
                        <div className="navigation">
                            <ul>
                                <li>
                                    <NavLink to="/" activeClassName="selected" exact={true} onClick={close}
                                             accessKey="1">
                                        {l('menu.search')}
                                    </NavLink>
                                </li>

                                {showReportDodeRovdyr && (
                                <li>
                                    <NavLink to="/rapport/doderovdyr" activeClassName="selected" onClick={close} >
                                        {l('menu.reportDodeRovdyr')}
                                    </NavLink>
                                </li>)}

                                {site === Site.NO && (
                                    <Fragment>
                                        <li>
                                            <NavLink to="/erstatning/sau" onClick={close} activeClassName="selected">
                                                {l('menu.refundSheep')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/erstatning/rein" onClick={close} activeClassName="selected">
                                                {l('menu.refundReindeer')}
                                            </NavLink>
                                        </li>

                                        <li>
                                            <button
                                                onClick={() => setOpenRovdyr(!openRovdyr)}
                                                aria-expanded={openRovdyr}
                                            >
                                                {l('menu.facts')}
                                                <ExpandMoreIcon
                                                    aria-hidden={true}
                                                    className={expandClass}
                                                    titleAccess={l('uu.expand')}
                                                />
                                            </button>
                                            {openRovdyr && (
                                                <ul className="rovdyrfakta">
                                                    <li>
                                                        <ExternalLink
                                                            href={'https://rovdata.no/Ulv.aspx'}>{l('carnivore.wolf')}</ExternalLink>
                                                    </li>
                                                    <li>
                                                        <ExternalLink
                                                            href={'https://rovdata.no/Gaupe.aspx'}>{l('carnivore.lynx')}</ExternalLink>
                                                    </li>
                                                    <li>
                                                        <ExternalLink
                                                            href={'https://rovdata.no/Jerv.aspx'}>{l('carnivore.wolverine')}</ExternalLink>
                                                    </li>
                                                    <li>
                                                        <ExternalLink
                                                            href={'https://rovdata.no/Brunbjørn.aspx'}>{l('carnivore.bear')}</ExternalLink>
                                                    </li>
                                                    <li>
                                                        <ExternalLink
                                                            href={'https://rovdata.no/Kongeørn.aspx'}>{l('carnivore.goldenEagle')}</ExternalLink>
                                                    </li>
                                                </ul>
                                            )}
                                        </li>
                                    </Fragment>
                                )}

                                <li>
                                    <NavLink to="/om" activeClassName="selected" onClick={close} accessKey="5">
                                        {l('menu.about')}
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/kontakt" activeClassName="selected" onClick={close} accessKey="7">
                                        {l('menu.contact')}
                                    </NavLink>
                                </li>
                                <li>
                                    <ExternalLink
                                        href={'https://www.miljodirektoratet.no/personvernerklaring/'}>{l('menu.privacyPolicy')}</ExternalLink>
                                </li>
                                <li>
                                    <NavLink to="/tilgjengelighet" activeClassName="selected" onClick={close}
                                             accessKey="0">
                                        {l('menu.accessibilityStatement')}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

                {/* Footer with contact information*/}
                <div className={menuFooterClass}>
                    <div className="contactinfo-container">
                        <Logo site={Site.NO} language={language} width={60} alt={l('contact.MDIR')} />
                        <div className="contactinfo-text">
                            <div>Miljødirektoratet</div>
                            <div>
                                <label>{l('menu.email')}: </label>
                                <a href={`mailto:${mdirInfo.mail}`}>{mdirInfo.mail}</a>
                            </div>
                            <div>
                                <label>{l('menu.phone')}: </label>
                                <a href={`tel:${stripPhoneNumber(mdirInfo.phone)}`}>{mdirInfo.phone}</a>
                            </div>
                        </div>
                    </div>
                    <div className="contactinfo-container">
                        <Logo site={Site.SE} language={language} width={60} alt={l('contact.Naturvardsverket')} />
                        <div className="contactinfo-text">
                            <div>Naturvårdsverket</div>
                            <div>
                                <label>{l('menu.email')}: </label>
                                <a href={`mailto:${naturvardsInfo.mail}`}>{naturvardsInfo.mail}</a>
                            </div>
                            <div>
                                <label>{l('menu.phone')}: </label>
                                <a href={`tel:${stripPhoneNumber(naturvardsInfo.phone)}`}>{naturvardsInfo.phone}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

