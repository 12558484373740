import {useEffect, useState} from 'react';
import {checkReportStatus} from '../services/reportService';

export function useDodeRovdyrRapport() : boolean {
    const [toggleReportDodeRovdyr, setToggleReportDodeRovdyr] = useState<boolean>(false);    
    
    useEffect(() => {
        async function fetchReportStatus():Promise<void> {
            const status = await checkReportStatus('doderovdyr');
            setToggleReportDodeRovdyr(status ?? false);
        }
        fetchReportStatus();
    }, []);
    
    return toggleReportDodeRovdyr;
}